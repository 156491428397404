/*********************************************/
/* Icones / Pictogrammes
/*********************************************/

.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

@media only screen and (max-width: 767px) {
  .zopim {
    transform: translateY(-185%) !important;
  }
}

.zopim-hide .zopim {
  top: -100px;
  visibility: hidden;
}

